.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: #1e90ff; /* Bluish background */
  color: white;
  border-bottom: 4px solid #1c86ee; /* Darker bluish bottom border */
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
  box-sizing: border-box; /* Include padding and border in element's total width and height */
}

.logo-container {
  display: flex;
  align-items: center;
  flex-shrink: 0; /* Prevents the logo from shrinking */
}

.logo {
  height: 40px;
  margin-right: 10px;
}

.logo-text {
  font-size: 24px;
  font-family: 'Montserrat', sans-serif;
}

.navbar ul {
  list-style-type: none;
  display: flex;
  gap: 20px;
  margin: 0;
  padding: 0;
  flex-wrap: wrap; /* Allows menu items to wrap to the next line if necessary */
}

.navbar li {
  position: relative;
}

.navbar a {
  color: white;
  text-decoration: none;
}

.navbar a:hover {
  text-decoration: underline;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #065f6e; /* Light bluish background */
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  left: 0; /* Align dropdown with parent */
}

.dropdown-content li {
  color: #00050a; /* Bluish text */
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content li:hover {
  background-color: #00050a; /* Darker bluish background on hover */
  color: white;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.logout-button {
  background: none;
  border: none;
  color: white;
  cursor: pointer;
  font-size: 16px;
  font-family: 'Montserrat', sans-serif;
  padding: 10px 20px;
}

.logout-button:hover {
  text-decoration: underline;
}

/* Mobile responsive styles */
@media (max-width: 768px) {
  .navbar {
    flex-direction: column;
    padding: 15px;
  }

  .logo-text {
    font-size: 20px; /* Adjust logo text size for smaller screens */
  }

  .navbar ul {
    flex-direction: column;
    gap: 10px; /* Reduce gap between menu items */
    width: 100%;
    align-items: center;
  }

  .navbar li {
    text-align: center; /* Center menu items on mobile */
  }

  .dropdown-content {
    left: 50%;
    transform: translateX(-50%); /* Center the dropdown on mobile */
  }

  .logout-button {
    width: 100%; /* Make logout button full-width on mobile */
    text-align: center;
  }
}
