.sidebar {
  width: 250px;
  height: calc(100vh - 60px); /* Adjust height based on the header height */
  background-color: #1f1f1f;
  color: #e0e0e0;
  position: fixed;
  top: 100px; /* Start the sidebar below the header */
  transition: width 0.3s ease;
  overflow: hidden;
  z-index: 1000; /* Ensure the sidebar is below the header */
}

.sidebar-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  background-color: #333;
}

.sidebar-header h2 {
  margin: 0;
  font-size: 18px;
}

.sidebar-menu {
  list-style: none;
  padding: 0;
}

.sidebar-menu li {
  padding: 15px;
}

.menu-item-link {
  color: #e0e0e0;
  text-decoration: none;
  display: block;
  font-size: 16px;
  cursor: pointer;
}

.menu-item-link:hover {
  background-color: #444;
}

/* Mobile responsive styles */
@media (max-width: 768px) {
  .sidebar {
    width: 200px; /* Reduce width on smaller screens */
    top: 60px; /* Align sidebar closer to the header */
    height: calc(100vh - 60px); /* Adjust height */
  }

  .sidebar-header {
    padding: 10px; /* Reduce padding for mobile */
  }

  .sidebar-header h2 {
    font-size: 16px; /* Adjust font size for smaller screens */
  }

  .sidebar-menu li {
    padding: 10px; /* Reduce padding for mobile */
  }

  .menu-item-link {
    font-size: 14px; /* Reduce font size for better readability on mobile */
  }

  /* Optional: Hide the sidebar completely for mobile and toggle with a button */
  .sidebar.hidden {
    width: 0;
    padding: 0;
    overflow: hidden;
  }
}
