.custom-button {
  background-color: #333; /* Darker background color */
  border: none;
  color: #e0e0e0;
  cursor: pointer;
  font-size: 14px;
  font-family: 'Montserrat', sans-serif;
  padding: 10px 20px;
  border-radius: 6px; /* Optional: Add border radius for a nicer look */
  transition: background-color 0.3s ease; /* Smooth transition for hover effect */
}

.custom-button:hover {
  background-color: #555; /* Different darker color on hover */
  text-decoration: none; /* Remove underline on hover */
}

.expand-button {
  background-color: #333;
  border: none;
  color: #e0e0e0;
  cursor: pointer;
  font-size: 14px;
  padding: 5px 10px;
  border-radius: 50%;
  transition: background-color 0.3s ease;
  display: inline-block;
  margin: 0 5px;
}

.expand-button:hover {
  background-color: #555;
  text-decoration: none;
}

/* Gradient button styles */
.gradient {
  background: linear-gradient(90deg, #4b6cb7, #182848); /* Bluish gradient */
  border: none;
  color: #fff;
  cursor: pointer;
  font-size: 14px;
  font-family: 'Montserrat', sans-serif;
  padding: 10px 20px;
  border-radius: 6px;
  transition: background 0.3s ease;
}

.gradient:hover {
  background: linear-gradient(90deg, #6a11cb, #2575fc); /* Pinkish gradient on hover */
  text-decoration: none;
}

/* Mobile responsive styles */
@media (max-width: 768px) {
  .custom-button,
  .gradient {
    font-size: 16px; /* Increase font size for better readability */
    padding: 15px 25px; /* Increase padding for better touch interaction */
    width: 100%; /* Make buttons full width on mobile */
    text-align: center; /* Center the text */
  }

  .expand-button {
    font-size: 18px; /* Increase the size for better touch interaction */
    padding: 10px; /* Add more padding */
    width: 50px; /* Set a fixed width for circular buttons */
    height: 50px; /* Set a fixed height */
  }
}
