.layout-container {
  display: flex;
  margin-top: 60px; /* Adjust based on the height of the header */
}

.sidebar {
  width: 250px;
  background-color: #2a2a2a; /* Dark sidebar background */
  color: white;
  padding: 10px;
  position: fixed;
  top: 65px; /* Adjust based on the height of the header */
  bottom: 0;
  overflow-y: auto;
  box-sizing: border-box; /* Ensure padding is included in the width */
}

.content {
  margin-left: 250px; /* Adjust to the width of the sidebar */
  padding: 20px;
  background-color: #121212; /* Dark background */
  color: #e0e0e0; /* Light text color */
  width: calc(100% - 250px); /* Adjust to the width of the sidebar */
  min-height: 100vh;
  box-sizing: border-box; /* Ensure padding is included in the width */
}

/* Mobile responsive styles */
@media (max-width: 768px) {
  .layout-container {
    flex-direction: column;
    margin-top: 60px;
  }

  .sidebar {
    width: 100%; /* Sidebar takes full width on mobile */
    height: auto; /* Let the height adjust to its content */
    position: relative; /* Sidebar is no longer fixed */
    top: 0;
    margin-bottom: 20px; /* Add spacing between sidebar and content */
  }

  .content {
    margin-left: 0; /* Remove left margin */
    width: 100%; /* Content takes full width */
    padding: 15px; /* Adjust padding for mobile screens */
  }
}
