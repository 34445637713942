.loading-overlay {
  position: fixed;
  width: 180px;
  height: 180px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgba(30, 30, 30, 0.85); /* Dark, semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.loading-spinner {
  border: 16px solid #ececec;       /* Light neutral border */
  border-top: 16px solid #9940ff;     /* Vibrant accent color */
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 2s linear infinite;
}


@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* Mobile responsive styles */
@media (max-width: 768px) {
  .loading-spinner {
    width: 80px;  /* Smaller size for mobile screens */
    height: 80px;
    border: 12px solid #f3f3f3;
    border-top: 12px solid #3498db;
  }
}
